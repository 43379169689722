import { state } from ":mods";
import { adminApis } from "../../apis";
import { createShowcaseInputs } from "../../forms";
import { Match, Show, Switch, createEffect, onMount } from "solid-js";

type ShowcaseFormProps = {
  stage: number | string;
  showcase?: number | string;
  buttonMsg?: string;
  onDone?: (
    values: ReturnType<ReturnType<typeof createShowcaseInputs>["Actions"]["getValuesSnakecase"]>,
    payload: FormData
  ) => void;
};
export function ShowcaseForm(props: ShowcaseFormProps) {
  const $details = state.createAsync([adminApis.detailsGet, props.showcase], { manualFetch: { startSuccess: true } });
  const Form = createShowcaseInputs(props.stage);

  $details.on((event, { value }) => {
    if (props.showcase) {
      // console.log("updating with module details");
      Form.Actions.update($details.value.data as any, null, ["Image"]);
    }
  });
  createEffect(() => {
    console.log("from effect of resource-form :: ", $details?.value?.data);
  });

  onMount(() => {
    if (props.showcase) {
      $details.refetch();
    }
  });
  function onSubmit() {
    const values = Form.Actions.getValuesSnakecase();
    if (values.image) {
      // @ts-ignore
      values.image = values.image?.[0];
    }
    const payload = new FormData();
    for (const key in values) {
      const value = values[key];
      if (value instanceof FileList) {
        payload.append(key, value[0]);
      } else {
        payload.append(key, value as string);
      }
    }
    props.onDone?.(values, payload);
  }
  return (
    <Switch>
      <Match when={$details.state.loading}>Loading Resource Details...</Match>
      <Match when={$details.state.failed}>An error occured while loading module details {$details.error}...</Match>
      <Match when={$details.state.success}>
        <div class="w-full h-full flex flex-col">
          <Form.ID.Label title="ID" />
          <Form.ID.Input class="form-input" type="text" />
          {/* <Form.Stage.Label title="Description" />
          <Form.Stage.Input class="form-input" type="text" /> */}
          <Form.Name.Label title="Name" />
          <Form.Name.Input class="form-input" type="text" />
          <Form.Description.Label title="Description" />
          <Form.Description.Input class="form-input" type="text" />
          <Form.Image.Label title="image">
            <Form.Image.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </Form.Image.Label>
          <Form.Image.InputFile
            accept="image/*"
            classes={{
              container:
                "flex flex-col justify-center items-center bg-inherit border-2 border#p border-dashed text-14px px-2.5 py-50px w-630px",
            }}
            elements={{
              input({ onInputClicked, viewer_data, drag }) {
                return (
                  <div
                    onclick={onInputClicked}
                    class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                  >
                    <i class="icon-local:upload-icon w-29.5px h-19.6px" />
                    <p class="ms-2px cursor-pointer">
                      <Show when={!drag} fallback={"Drop Files"}>
                        Drag files here or <span class="underline">choose a file</span>
                      </Show>
                    </p>
                    <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                  </div>
                );
              },
            }}
          />
          <Form.Actions.Button
            class="form-btn !w-fit !min-h-50px px-2 mt-2"
            statusValid={props.buttonMsg ?? "Create Showcase"}
            onSubmit={onSubmit}
            onlyStatus={["valid"]}
          />
        </div>
      </Match>
    </Switch>
  );
}
